<template>
  <div>
    <base-header base-title="Manajemen Notifikasi"></base-header>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="'Manajemen Notifikasi'"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
                 <router-link :to="{name: 'EditManajementNotifikasi',
                    params: { id: 'add' }}">
                     <button  class="btn btn-sm btn-outline-primary">
                        <i class="flaticon2-add-1"></i> Tambah Template
                     </button>
                </router-link>
              
            </div>
          </div>
        </div>
        <div class="kt-portlet__body">
           <v-server-table   
           @loaded="onLoaded(0)"
           @loading="onLoaded(1)"
           :columns="columns"
           :options="options"
           ref="table">
              <div slot="templateMethod" slot-scope="{ row }" class="d-inline-block ">
                  <div v-for="(temp,index) in row.templateMethod" class="d-inline-block font-weight-bold mr-2 px-2 py-1" :key="index" :style="{border: `2px solid ${colors[temp]}`, color: `${colors[temp]}`}">
                    {{temp}}
                  </div>
              </div>
              <div slot="action" slot-scope="{ row }">
                  <router-link tag="button"  class=" btn btn-sm btn-clean btn-icon btn-icon-md" :to="{name: 'EditManajementNotifikasi',
                    params: { id: row.templateId }}" :id="`template-${row.templateId}`">
                     <i class="la la-external-link external-link" title="Detail"></i>
                </router-link>
                <router-link tag="button"  class=" btn btn-sm btn-clean btn-icon btn-icon-md" :to="{name: 'HistoryManajementNotifikasi', 
                    params: { id: row.templateId }}" :id="`router-template-${row.templateId}`">
                     <i class="la la-columns external-link" title="History"></i>
                </router-link>
                <!-- <button
                  @click="goToChannels(row.templateId)"
                  class="btn btn-sm btn-clean btn-icon btn-icon-md"
                  :title="`go to template` "
                >
                <i class="la la-external-link"></i>
                </button> -->
              </div>
              <div v-if="isLoading " slot="afterBody" >
                  <h6 class="txt"> loading..</h6>
              </div>
            </v-server-table> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from "./../../components/_base/BaseTitle";
import BaseHeader from "./../../components/_base/BaseHeader";
import { mapState, mapActions } from "vuex";
import BaseButton from "./../../components/_base/BaseButton";
import { RepositoryFactory } from "../repositories/BaseRepositoryFactory"
const NotifikasiChannel = RepositoryFactory.get('NotifikasiChannel')

export default {
  components: {
    BaseTitle,
    BaseHeader,
    BaseButton
  },
  data() {
     var vx = this;
    return {
      isLoading: false,
      colors: {
          SMS: 'black',
          FCM: 'red',
          WA: 'green',
          EMAIL: 'blue'
      },
      columns: ["templateId","templateName", "templateMethod", "action"],
      options: {
        // skin: 'table table-bordered table-striped',
        sortIcon: {
            base: "fa",
            is: "fa-sort",
            up: "fa-sort-up",
            down: "fa-sort-down "
        },
        sortable: ['templateId','templateName'],
        texts: {
          filterBy: "Cari",
          loading: 'Loading...'
        },
        headings: {
          templateId: "Template Id",
          templateName: "Nama Template",
          templateMethod: "Channel",
          action: "Actions",
        },
        filterable: ["templateName", "templateId"],
        filterByColumn: true,
        stickyHeader: true,
        debounce: 500,
        requestFunction: function (data) {
          let params = {};
          params.page = data.page;
          params.size = data.limit;
          vx.options.texts.loading = 'Loading...'
        //   params.sort= 'createdDate,desc'
          if(!_.isEmpty(data.query)) {
              if(data.query.templateName) {
                  params['templateName.contains'] = `${data.query.templateName}`;
              } 
              if(data.query.templateId) {
                  params['templateId.contains'] = `${data.query.templateId}`;
              }
          }
          if(data.orderBy) {
            params.sort = `${vx.checkParams(data.orderBy)},${data.ascending == "1" ? 'desc': 'asc'}`
          }
          return NotifikasiChannel.fetch(params).catch(function (e) {
              toastr.error('Error Get Data');
               vx.options.texts.loading = 'Data tidak ditemukan'
          });
        },
        responseAdapter: (resp) => {  
            return {
                data: resp.data ?? [],
                count: resp.headers['x-total-count'] ? 
                   resp.headers['x-total-count'] : resp.headers['Content-Length'] ? 
                   resp.headers['Content-Length'] : (resp.data ? resp.data.length : 0)
            }
        },
      },
    };
  },
  computed: {
    ...mapState("NotifikasiStore", {
      listAllTemplate: (state) => state.listAllTemplate,
      isLoadingNotifikasi: (state)=> state.isLoadingNotifikasi
    }),
  },
  methods: {
    checkParams(item) {
      if(item == 'templateName') {
        return `template_name`
      } else {
        return 'template_id'
      }
    },
    goToChannels(templateId) {
      this.$router.push({
        name: "EditManajementNotifikasi",
        params: { id: templateId },
      });
    },
  // ...mapActions({
  //   getAllTemplate : 'NotifikasiStore/GET_TEMPLATE_CHANNEL'
  // }),
  onLoaded: function(t) {
            t == 0 ? this.isLoading = false : this.isLoading = true  
        },
  }
};
</script>

<style>
</style>